import styled from "@emotion/styled";
import React from "react";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {
  onLight?: boolean;
}

const LOGO_SIZE_W = 132;
const LOGO_SIZE_H = 18;

const Root = styled.a<IProps>`
  display: block;
  position: relative;
  font-family: var(--common-font);
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;
  width: ${LOGO_SIZE_W}px;
  height: ${LOGO_SIZE_H}px;
  object-fit: contain;
`;

const Logo: React.FC<IProps> = ({ onLight }) => {
  const dictionary = useLanguage();
  const iconColor = onLight ? '#A51537' : '#FFFFFF';
  return (
    <Root href="/">
      <svg width={LOGO_SIZE_W} height={LOGO_SIZE_H} viewBox="0 0 499 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_6_2)">
          <path d="M142.635 66.5548H156.324V12.8439H173.728V0.00215251H142.635V66.5548Z" fill={iconColor}/>
          <path d="M308.077 12.8439H321.763V66.5548H335.451V12.8439H349.133V0.00215251H308.077V12.8439Z" fill={iconColor}/>
          <path d="M27.1917 36.81H13.3823V12.847H27.1917V36.81ZM0 0.00215251V66.5548H13.3823V49.6538H30.7964L40.8817 40.0288V9.62308L31.2609 0.00215251H0Z" fill={iconColor}/>
          <path d="M78.4328 53.7126H62.3869V12.8444H78.4328V53.7126ZM58.3145 0.00215251L48.6932 9.62308V56.9298L58.3208 66.5548H82.488L92.1265 56.9298V9.62308L82.488 0.00215251H58.3145Z" fill={iconColor}/>
          <path d="M114.475 12.8439H133.877V0.0021396H110.409L100.786 9.62307V56.9308L110.409 66.56H133.985V53.7126H114.475V12.8439Z" fill={iconColor}/>
          <path d="M207.631 53.7126H191.584V12.8444H207.631V53.7126ZM187.512 0.00215251L177.891 9.62308V56.9298L187.519 66.5548H211.685L221.324 56.9298V9.62308L211.685 0.00215251H187.512Z" fill={iconColor}/>
          <path d="M383.447 36.81H369.637V12.847H383.447V36.81ZM356.256 0.00215251V66.5548H369.637V49.6538H387.053L397.137 40.0288V9.62308L387.517 0.00215251H356.256Z" fill={iconColor}/>
          <path d="M243.673 12.8439H263.075V0.0021396H239.608L229.984 9.62307V56.9308L239.608 66.56H263.183V53.7126H243.673V12.8439Z" fill={iconColor}/>
          <path d="M283.449 12.8439H302.852V0.0021396H279.384L269.761 9.62307V56.9308L279.384 66.56H302.96V53.7126H283.449V12.8439Z" fill={iconColor}/>
          <path d="M488.725 32.4324L498.455 -0.00146484H484.735L477.335 25.1412H474.867L467.468 -0.00146484H453.745L463.501 32.5725L453.615 66.5511H467.141L474.995 40.3064H477.207L485.068 66.5511H498.585L488.725 32.4324Z" fill={iconColor}/>
          <path d="M418.676 38.184L423.64 12.8407H426.252L431.217 38.184H418.676ZM436.775 66.5511H450.548L436.812 -0.00146484H413.42L399.684 66.5511H413.119L416.227 50.686H433.667L436.775 66.5511Z" fill={iconColor}/>
        </g>
        <defs>
          <clipPath id="clip0_6_2">
            <rect width="498.587" height="66.56" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </Root>
  );
};

export default Logo;
