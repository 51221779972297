import Logo from "@components/Header/Logo";
import styled from "@emotion/styled";
import React from "react";
import { ReactComponent as Close } from "@src/assets/CloseMenu.svg";
import { FlexContainer } from "@components/FlexContainer";
import { useStores } from "@stores";
import { layoutStyle } from "@components/Layout";
import Bottom from "./Bottom";
import { useObserver } from "mobx-react-lite";
import { ROUTES } from "@stores/RouterStore";
import { useHistory } from "react-router-dom";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {
  onClose: () => void;
}

const Root = styled.div`
  position: fixed;
  z-index: 5;
  display: flex;
`;
const Top = styled.div`
  background: #990032;
  position: fixed;
  top: 0;
  bottom: 50%;
  left: 0;
  right: 0;
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  ${layoutStyle};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;
const Background = styled.div`
  position: fixed;
  z-index: 4;
  background: #4d4d4d;
  opacity: 0.5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
const Text = styled.div`
  display: flex;
  font-family: var(--common-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
`;
const LangIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;
const Menu: React.FC<IProps> = ({ onClose }) => {
  const { accountStore, settingsStore } = useStores();
  const history = useHistory();
  const dictionary = useLanguage();
  const signOut = async () => {
    await accountStore.signOut();
    history.push(ROUTES.ROOT);
    window.location.reload();
  };
  return useObserver(() => (
    <Root>
      <Background onClick={onClose} />
      <Top>
        <Info>
          <FlexContainer
            style={{ paddingTop: 15 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Logo onLight={false} />
            <div />
            <Close onClick={onClose} style={{ cursor: "pointer" }} />
          </FlexContainer>
          <FlexContainer
            alignItems="center"
            justifyContent="space-between"
            style={{ paddingBottom: 22 }}
          >
            {!accountStore.user ? (
              <Text onClick={() => history.push(ROUTES.LOGIN)}>
                {dictionary.login}
              </Text>
            ) : (
              <Text onClick={signOut}>{dictionary.logout}</Text>
            )}
            <Row onClick={settingsStore.toggleLanguage}>
              <LangIcon src={settingsStore.langIcon} />
              <Text>{settingsStore.language}</Text>
            </Row>
          </FlexContainer>
        </Info>
        <Bottom />
      </Top>
    </Root>
  ));
};
export default Menu;
