import styled from "@emotion/styled";
import React from "react";
import image from "@src/assets/hands.svg";
import Header from "@components/Header";
import Layout, { MAX_WIDTH } from "@components/Layout";

interface IProps {
  title: string;
  subtitle: string;
}

const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #e6e6ea;
  padding-bottom: 15px;
`;

const Title = styled.div`
  font-family: var(--common-font);
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: var(--accent-color);
`;
const Subtitle = styled.div`
  font-family: var(--common-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-top: 15px;
`;

const Img = styled.img`
  width: 100vw;
  max-width: ${MAX_WIDTH}px;
  margin: 25px -16px;
`;

const SplashScreen: React.FC<IProps> = ({ title, subtitle, children }) => {
  return (
    <Root>
      <Layout>
        <Header logoOnLight={true} />
        <Img src={image} alt="logo" />
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        {children}
      </Layout>
    </Root>
  );
};

export default SplashScreen;
